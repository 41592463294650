.mapboxgl-ctrl-top-left {
  top: 48px;
}

.mapContainer {
  height: calc(100vh - 42px);
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

.mapContainer.mobile {
  height: 50vh;
}

/* .compsMapContainer {
    height: 39.8vh;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
} */

.vasquette-margin-enabled#omnibox {
  margin: 8px 0 8px 8px;
}

#omnibox {
  min-width: 350px;
  position: absolute;
  left: 0px;
  margin: 16px;
  top: 0px;
  z-index: 999;
  transition: left 0.5s;
  -webkit-transform: translateX(0px);
  transform: translateX(0px);
  transition-property: -webkit-transform,transform,visibility,opacity;
  transition-duration: 200ms;
  transition-timing-function: cubic-bezier(0.0,0.0,0.2,1);
}

#omnibox input {
  text-transform: uppercase;
}

#omnibox .ui.circular.segment {
  padding: 1em;
  width: 44px;
  height: 44px;
}

#omnibox .dropdown.icon {
  display: none;
}

.ui.button.btn_comps:disabled, .ui.disabled.button.btn_comps {
  pointer-events: auto !important;
  cursor: not-allowed;
}

.comps-chart-tabs > .ui.attached.tabular.menu {
  overflow: scroll hidden;
}

.comps-searchpanel {
  width: 100%;
  margin: 8px 2px;
}

.sidebarStyle {
  position: relative;
  background-color: #404040;
  color: #ffffff;
  padding: 6px;
  font-weight: bold;
}

.clear-top {
  margin-top: 0 !important;
}

.clear-bottom {
  margin-bottom: 0 !important;
}

.lot-details .row,
.fastclose-dynamicomp-details .row,
.fastclose-lot-details .row {
  padding: 0 2px !important
}

.lot-details > .row:first-child,
.fastclose-dynamicomp-details > .row:first-child,
.fastclose-lot-details > .row:first-child {
  margin-top: 1rem;
}

.lot-details > .row:last-child,
.fastclose-dynamicomp-details > .row:last-child,
.fastclose-lot-details > .row:last-child {
  margin-bottom: 1rem;
}

.lot-details .row > div, 
.fastclose-dynamicomp-details .row > div,
.fastclose-lot-details .row > div {
  font-size: 13px;
}

.address-display-container {
  position: absolute;
  top: 5px;
  right: 30px;
  width: 300px;
  transition: all ease-in-out 0.2s;
  z-index: 999;
  text-align: center;
}

.address-display-container.info-visible {
  right: 505px;
}

.address-display-container > .address-display-panel {
  height: 32px;
  padding: 0.5em !important;
}

.map-view-container {
  position: relative;
  width: 100%;
  height: 300px;
  transition: all ease-in-out 0.2s;
  /* z-index: 999; */
}

/*.home .home-container.right-panel .map-view-container {
  right: 480px;
}*/

/* .home .home-container.comps-visible .map-view-container {
  bottom: 460px;
  width: 300px !important;
  height: 225px !important;
} */

.map-view-container.mouse-over {
  width: 600px;
  height: 450px;
}

.map-view {
  width: 100%;
  height: 100%;
  border: 0;
}

.map-view#panorama-view canvas {
  width: 100% !important;
  height: 100% !important;
} 

.map-button {
  position: absolute;
  top: 10px;
  left: 0.75em;
  z-index: 99;
  width: 40px;
  height: 40px;
  background: white !important;
}

.ui.search > .input {
  width: 100%;
}

/* .comps-table .rdt_Table .rdt_TableHead .rdt_TableHeadRow .rdt_TableCol:first-child {
  width: 50px !important;
  min-width: 50px !important;
  max-width: 50px !important;
  padding: 0 28px !important;
} */

.comps-table td, .comps-table th, .comps-table .rdt_TableCell, .comps-table .rdt_TableCol,
.prospecting-table td, .prospecting-table th, .prospecting-table .rdt_TableCell, .prospecting-table .rdt_TableCol {
  min-width: 100px;
  max-width: 150px;
}

.rdt_Table .rdt_TableBody {
  overflow-y: auto;
}

.reports-table .rdt_Table .rdt_TableHead .rdt_TableHeadRow .rdt_TableCol > div > div {
  width: min-content
}

.reports-table .reports-table-footer {
  height: 32px;
  border: 0 !important;
  width: 100%;
  margin: 1rem auto !important;
  padding: 0 0.25rem !important;
}

.reports-table .reports-table-footer > .ui.segment {
  padding: 0.5em 1em;
  border: 0 !important;
  font-weight: bolder;
}

.reports-table .reports-table-footer > .ui.segment:first-child {
  width: 242.5px;
}

.reports-table .reports-table-footer > .ui.segment:not(:first-child) {
  text-align: center;
  width: 125px;
}

.reports-table .vacate-status-active {
  color: red;
}

.reports-table .vacate-status-closed {
  color: blue;
}

.reports-table .vacate-status-active, .reports-table .vacate-status-closed {
  font-weight: bolder;
  text-transform: uppercase;
}

.reports-sub-table-description-label {
  font-size: 11px;
  margin: 0 1em;
}

input {
  text-transform: uppercase;
}

.bldg-class-name {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* .comps-table td.deed-party-ddeed-party-d, .comps-table th.deed-party-h {
  max-width: 240px !important;
} */

.deed-party-container {
  /* max-width: calc(100% - 45px); */
  height: 32px;
  margin: -0.25rem 0;
  overflow: hidden;
}

.deed-party-container > .deed-party {
  width: 100%;
  display: block;
  font-weight: 600;
  font-size: 11px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  height: 16px;
}

.nowrap {
  width:100%;
  display:block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.comps .column.comps-filter-col {
  width: 17.5% !important;
  height: 98vh !important;
  min-width: 334px !important;
  max-width: 334px !important;
}

 .comps-filter-scrollable /* , .prospecting-filter-scrollable  */ {
  max-height: calc(80vh - 250px) !important;
}

.prospecting-filter-scrollable {
  max-height: 50vh !important;
}


/* .comps .column.comps-filter-col .comps-filter-scrollable {
  overflow: hidden;
  margin-bottom: 32px;
  padding: 0;
  height: 88%;
} */

/* .comps .column.comps-filter-col .comps-filter-scrollable > section {
  height: 100%;
  overflow-y: auto;
} */

.comps .column.comps-table-col {
  /* width: 82.5% !important; */
  width: calc(100% - 334px) !important;
  height: 98.6vh !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.comps-filter-container .filter-input-group > .ui.input,
 .prospecting-filter-container .filter-input-group > .ui.input {
  max-width: 45%;
}

.comps-filter-container .filter-input-group > .ui.input > input,
.prospecting-filter-container .filter-input-group > .ui.input > input {
  width: 125px !important;
}

.comps-filter-container .filter-input-group > .label,
.prospecting-filter-container .filter-input-group > .label {
  border: 0 !important;
}

.comps-filter-container .filter-input-group,
.prospecting-filter-container .filter-input-group {
  padding-top: 0 !important;
  padding-bottom: 0.5em !important;
}

/* .comps-table, .comps-google-table-view-container {
  min-height: 575px;
} */

.comps-table .rdt_Table .rdt_TableBody,
.prospecting-table .rdt_Table .rdt_TableBody {
  max-height: 46vh;
}

.comps-table .rdt_Table .rdt_TableHead .rdt_TableHeadRow,
.prospecting-table .rdt_Table .rdt_TableHead .rdt_TableHeadRow {
  min-height: 32px !important;
  white-space: nowrap;
}

.comps-table .ui.label.comps-address,
.prospecting-table .ui.label.prospecting-address {
  border: 0;
  cursor: pointer;
}

.home .ui.sidebar.comps-filter-container,
.home .ui.sidebar.comps-table-container,
.home .ui.sidebar.prospecting-filter-container.comps-mode,
.home .ui.sidebar.prospecting-table-container {
  display: none;
}

.ui.sidebar.side-panel-container,
.ui.sidebar.comps-filter-container.comps-mode,
.ui.sidebar.comps-table-container.comps-mode,
.ui.sidebar.comps-side-panel-container,
.ui.sidebar.comps-excluded-side-panel-container,
.ui.sidebar.prospecting-filter-container,
.ui.sidebar.prospecting-table-container.prospecting-mode  {
  overflow: unset !important;
  visibility: inherit !important;
  display: inherit;
}

.ui.sidebar.comps-side-panel-container {
  z-index: 99 !important;  
  padding-bottom: 0 !important;
}

.ui.sidebar.comps-excluded-side-panel-container {
  top: 35px;
  z-index: 98 !important;  
  padding-bottom: 0 !important;
  height: 55vh !important;
  overflow: hidden;
}

.home .home-container.comps-visible .ui.sidebar.comps-side-panel-container {
  height: 40vh !important;  
}

.home .home-container.comps-visible .ui.sidebar.comps-side-panel-container.combined-comps-side-panel-container {
  height: 100vh !important;  
}

.grid.lot-details > .row > .column:last-child > span, 
.grid.lot-details > .row > .column:last-child > div.ui.input {
  float: right;
  text-align: right;
  padding-right: 24px;
}

.grid.fastclose-dynamicomp-details > .row > .column:last-child {
  text-align: center;
  /* padding-right: 24px; */  
}

.grid.fastclose-lot-details > .row > .column:first-child > span {
  padding-left: 0 !important;
  font-weight: 600;
}

.grid.fastclose-lot-details > .row > .column:last-child {
  padding: 0 !important;
}

.grid.lot-details > .row > .column:first-child > span {
  padding-left: 24px;
  font-weight: 600;
}

.grid.fastclose-dynamicomp-details > .row > .column:first-child > span {
  padding-left: 8px;
  font-weight: 600;
}

.comps-side-panel-container.ui[class*="very wide"].right.sidebar {
  width: 500px;
} 

.combined-comps-side-panel-container.ui[class*="very wide"].right.sidebar {
  width: 1000px !important;
} 

.combined-comps-container {
  display: flex;
}

.home .home-container.right-panel .ui.sidebar.comps-side-panel-container {
  right: 475px !important;
}

.comps-excluded-side-panel-container.ui[class*="very wide"].right.sidebar {
  width: 500px;
} 

.home .home-container.right-panel .ui.sidebar.comps-excluded-side-panel-container {
  right: 475px !important;
}

.home .home-container.right-panel.comps-panel .ui.sidebar.comps-excluded-side-panel-container {
  right: 975px !important;
}

.ui.visible.wide.left.sidebar~.fixed, .ui.visible.wide.left.sidebar~.pusher {
  transform: translate3d(350px,0,0) !important;
}

.home .home-container.right-panel .ui.sidebar.side-panel-container {
  /* min-width: 475px !important;
  max-width: 475px !important; */
}

.home .ui.sidebar.side-panel-container .side-panel-grid > div.row {
  padding-bottom: 0 !important;
}

.home .ui.sidebar.comps-filter-container ,
.home .ui.sidebar.prospecting-filter-container {
  top: inherit;
  bottom: 0;
  height: 99.5% !important;
  /* min-width: 350px !important;
  max-width: 350px !important; */
  z-index: 9999;
}

.comps-table-container, .prospecting-table-container {
  transition: all ease-in-out 0.5s !important;
}

.home .home-container.filter-panel.right-panel .ui.bottom.sidebar.comps-table-container,
.home .home-container.filter-panel.right-panel .ui.bottom.sidebar.prospecting-table-container {
  left: 345px;
  width: calc(100% - (345px + 475px)) !important;
}

.home .home-container.filter-panel .ui.bottom.sidebar.comps-table-container,
.home .home-container.filter-panel .ui.bottom.sidebar.prospecting-table-container {
  left: 345px;
  width: calc(100% - 345px) !important;
}

.home .home-container.right-panel .ui.bottom.sidebar.comps-table-container,
.home .home-container.right-panel .ui.bottom.sidebar.prospecting-table-container {
  right: 475px;
  width: calc(100% - 475px) !important;
}

.home .comps-table-container .comps-table,
.home .prospecting-table-container .prospecting-table {
  max-height: 50vh;
  min-height: 50vh;
}

.panel-closer.floating {
  cursor: pointer;
}

.ui.label.floating.panel-closer {
  width: 28px !important;
}

.ui.label.panel-closer.floating.left, .ui.label.panel-closer.floating.right {
  top: 0.5em;
}

.ui.label.panel-closer.comps-panel-closer.floating.left {
  top: 2.5em !important;
}

.ui.floating.label.panel-closer.up {
  top: -1.5em !important;
  border-bottom: 0 !important;
  left: calc(50% + 4px) !important;
}

.ui.floating.label.panel-closer.right {
  border-left: 0 !important;
  margin: 0 0 0 -0.5em !important;
}

.ui.floating.label.panel-closer.left {
  border-right: 0 !important;
  left: 0 !important;
  margin: 0 0 0 -2em !important;
}

.ui.floating.label.panel-closer.left > i {
  margin-left: -0.55em !important;
}

.stats-group > div {
  display: table;
  border-collapse: separate;
  margin: -24px auto;
  border-spacing: 16px;
}

.stats-group .stat-counter {
  width: 96px;
  height: 64px;
  padding: 0em !important;
  border-radius: 4px !important;
}

.stats-group .stat-counter .header:nth-child(2) {
  margin-bottom: 0 !important;
  color: red;
}

.stats-group .stat-counter .header:nth-child(3) {
  margin: 0 !important;
  color: black;  
}

.badges-group {
  display: flex;
  flex-direction: row-reverse;
  margin: -0.5em auto;
}

.badges-group .badge-item {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: help;
}

/* .badges-group .badge-item .badge-label {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background: transparent !important;
  color: white !important;
  height: 75%;
  width: 90%;
  margin: auto 0;
} */

.badges-group .badge-item .badge-label {
  margin-left: 4px !important ;
  width: 75px !important;
}

.ui.selection.dropdown .menu > .item {
  font-size: 90%;
}

.comps-popup-view-container {
  position: absolute !important;
  bottom: 5px;
  left: 5px;
  width: 600px !important;
  min-height: 250px;
  max-height: 325px;
  transition: all ease-in-out 0.2s;
  z-index: 999;
  margin: 0 !important;
}

.mapbox-comps-popup-view-container.down {
  bottom: -300px;
}

.mapbox-comps-popup-view-container.left {
  left: -500px;
}

.comps-calc-view-container {
  /* width: 375px;
  height: 275px; */
  
  transition: all ease-in-out 0.2s;
  z-index: 999;
  margin: 0 !important;
  text-align: center;
}

.comps-calc-view-container .divider {
  font-size: 12px;
}

.ui.grid .column+.ui.vertical.divider.valuation-difference {
  height: 50% !important;
}

.ui.vertical.divider.divider-entry-middle-difference {
  background-color: green;
  background-image: linear-gradient(90deg, green, #ffc100);
}

.ui.vertical.divider.divider-entry-middle-low-difference {
  background-color: green;
  background-image: linear-gradient(90deg, green, teal);
}

.ui.vertical.divider.divider-middle-low-middle-difference {
  background-color: teal;
  background-image: linear-gradient(90deg, teal, #ffc100);
}

.ui.vertical.divider.divider-middle-middle-high-difference {
  background-color: #ffc100;
  background-image: linear-gradient(90deg, #ffc100, #ff7400);
}

.ui.vertical.divider.divider-middle-high-exit-difference {
  background-color: #ff7400;
  background-image: linear-gradient(90deg, #ff7400, #ff0000);
}

.ui.vertical.divider.divider-middle-exit-difference {
  background-color: #ffc100;
  background-image: linear-gradient(90deg, #ffc100, #ff0000);
}

.ui.horizontal.divider.divider-entry-exit-difference {
  width: 70%;
  margin: 0 auto 1rem;
  background-color: green;
  background-image: linear-gradient(90deg, green, #ff0000);
}

.ui.horizontal.divider.divider-entry-exit-difference:after, .ui.horizontal.divider.divider-entry-exit-difference:before {
  background-image: none;
}

.valuation-difference {
  line-height: 1 !important;
  background-size: 100%;
  background-clip: text;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent; 
  -moz-text-fill-color: transparent;  
}

/* .ui.horizontal.divider.divider-entry-exit-difference:before {
  border-bottom-left-radius: 45px !important;
  border-bottom: 1px solid #cacaca !important;
  bottom: 4px;
  right: 8px
}

.ui.horizontal.divider.divider-entry-exit-difference:after {
  border-bottom-right-radius: 45px !important;
  border-bottom: 1px solid #cacaca !important;
  bottom: 4px;
  left: 8px;
} */

/* .home .home-container.filter-panel .comps-calc-view-container {
  left: 355px;
} */

/* .home .home-container.comps-visible .comps-calc-view-container {
  bottom: 460px;
} */

.home .home-container.filter-panel .comps-popup-view-container {
  left: 365px;
}

.connections-table .connections-label-group {
  line-height: 0.5em;
  white-space: nowrap;
}

.compColumn {
  width: 10px;
  margin-right: -15px;
  margin-left: -15px;
}

.compGrid {
  width: 375px;
}

.compRow {
  padding: 0 2px !important;
}

.mapboxgl-marker.mapboxgl-marker-anchor-center.deselected > svg > g > g {
  fill: grey !important;
}

.mapboxgl-marker.mapboxgl-marker-anchor-center.dropped > svg > g > g {
  fill: black !important;
}

.lot-map {
  margin-bottom: -32px !important;
}

/** Wide Street Dropdown **/
.wide-street-dropdown, .proxcode-dropdown {
  height: 16px;
}

.wide-street-dropdown > div.selection,
.proxcode-dropdown > div.selection {
  min-height: 0 !important;
  padding: 4px 0px 4px 8px !important;
}

.wide-street-dropdown > div.selection > i.dropdown.icon,
.proxcode-dropdown > div.selection > i.dropdown.icon {
  display: none;
}

.wide-street-dropdown > div.dropdown.selection > div.menu > .item,
.proxcode-dropdown > div.dropdown.selection > div.menu > .item {
  padding: 4px !important;
}

.ui.segment.valuation-shade:before {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  background: rgba(255,255,255,.8);
  width: 100%;
  height: 100%;
  border-radius: 0.28571429rem;
  z-index: 100;
}

.ui.divider.valuation-shade {
  display:none;
}


.popup-tds-container > .segment {
  background-color: #384353;
  border: 2 solid tomato;
  color: white;
}

.popup-tds-list {
  font-size: 1rem;
}

.popup-tds-list > .row {
  height: 32px;
  margin-top: 0rem;
  margin-bottom: -.5rem;
  overflow: hidden;
}

/* .popup-tds-list > .row:first-child {
  margin-top: 0rem;
} */

.popup-tds-list > .row:last-child {
  margin-bottom: 2rem;
}

.grid.popup-tds-list > .row > .column:last-child > span {
  float: right;
  text-align: right;
  padding-right: 6px;
  font-size: 0.85rem;
  text-overflow: ellipsis;
}

.grid.popup-tds-list > .row > .column:first-child > span {
  padding-left: 6px;
  font-weight: 600;
}

.popup-tds-chart {
  min-width: 75vw;
  max-width: 100% !important;
  
}

.popup-sale-chart, .popup-tds-chart {
  transform: translate3d(25px, 85px, 0px) !important;
}

.popup-sale-chart .popup-sale-chart-container, .popup-tds-chart .popup-tds-chart-container {
  width: 50vh;
  max-height: 55vh;
}

.popup-radius-chart .popup-radius-chart-container > .VictoryContainer:first-child {
  margin-bottom: -48px;
}

.editable-text-input,
.proxcode-dropdown {
  width: 60%;
  margin-left: 6px;
}

.proxcode-dropdown {
  margin-bottom: .5em !important;
}

.proxcode-dropdown > .editable-dropdown-input  {
  min-width: 100% !important;
  text-align: right !important;
  
}

.proxcode-dropdown > .editable-dropdown-input > div.default.text {
  text-align: right !important;  
  padding: 0 0.25em !important;
  font-family: 'Work Sans' !important;
}

.editable-text-input > input  {
  text-align: right !important;
  padding: 0 0.25em !important;
  font-size: 13px;
  font-family: 'Work Sans' !important;
}

.editable-text-input-icon {
  cursor: pointer;
  float: right;
  line-height: inherit !important;
}

.editable-text-input-icon.lightgray {
  color:lightgray
}

.editable-text-input-icon.blue {
  color:lightblue
}

.prospecting-owner-name, .prospecting-lender-name {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: pointer;
}

.total-investment-popup {
  min-width: 350px !important;
}

.pu-grid {
  min-width: 300px;
}

.pu-grid-li > span:last-child {
  float: right;
  text-align: right;
}

.mapboxgl-marker:not(.comp-marker) {
  z-index: 9;
}

.mapboxgl-marker.comp-marker {  
  color: white;
  padding: .25em;
  /* border-radius: 4em; */
  box-shadow: 1px 1px 1px gray;
  text-shadow: 1px 1px 1px gray;
  min-width: 45px;
  max-width: 45px;
  text-align: center;
  font-size: 1em !important;
  font-weight: bold;
  line-height: 1;
}

.mapboxgl-marker.comp-marker:before {
  content: "";
  position: absolute;
  top: 100%;
  left: 16px;
  width: 0;
  border-top: 7.5px solid gray;
  border-left: 7.5px solid transparent;
  border-right: 7.5px solid transparent;
}

.badge {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  padding: 2px 8px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  letter-spacing: -0.04em;
  text-transform: capitalize;
  mix-blend-mode: multiply;
  border-radius: 16px;
  background: #ecfdf3;
  color: #027a48;
}

.badge.red {
  background: #fef3f2;
  color: #b42318; 
}

.badge.orange {
  background: #fef7f2;
  color: #ff7400; 
}

.badge.yellow {
  background: #fefdf2;
  color: #ffc100; 
}

.badge.teal {
  background: #ecfdfb;
  color: #20b2aa; 
}

.badge.green {
  background: #ecfdf3;
  color: #027a48; 
}

.badge.blue {
  background: #eff8ff;
  color: #175cd3;
}

.quick-comps-container {
  background-image: url('../images/HeroImage.png') !important;
  background-position: center !important;
  /* background-attachment: fixed !important; */
  background-size: cover !important;
  background-repeat: no-repeat !important;
}

.quick-comps-inner-container {
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: center; 
}

.historical-chart-container {
  position: absolute !important;
  width: 800px;
  height: 500px;
  z-index: 999;
  top: 1%;
  left: 19%;
}

.dark-overlay {
  position: relative;
}

.dark-overlay::before {
  content: '';
  position: absolute;
  border-radius: 4px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(241, 241, 241, 0.55); /* Adjust the opacity as needed */
  pointer-events: none; /* Ensure it doesn't interfere with any interactions */
}

.breadcrumbs-container {
  padding: 0 1em;
  height: 24px;
  display: flex;
  gap: 0.25em;
}

.breadcrumbs-container > .breadcrumbs-cell {
  display: flex;
  gap: 0.25em;
  align-items: center;
}

.breadcrumbs-container > .breadcrumbs-cell > .label {
  cursor: pointer;
}

/* Container for the entire search component */
.search-input-container {
  position: relative;
  width: 100%;
}

/* The Semantic UI input wrapper */
.ui.icon.input {
  width: 100%;
  position: relative;
}

/* Custom wrapper for the icon/spinner */
.icon-wrapper {
  position: absolute;
  top: 50%;
  right: 1em;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 14px;
  width: 14px;
  line-height: 1;
  z-index: 2;
  pointer-events: none; /* Make sure it doesn't interfere with input interactions */
}

/* Make sure the actual icon (when not spinning) is properly centered */
.icon-wrapper i.icon {
  margin: 0;
  font-size: 14px;
  height: 14px;
  width: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ui.micro.button, .ui.micro.buttons .button, .ui.micro.buttons .or {
  font-size: .58571429rem;
}